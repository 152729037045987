import React from "react"
import PropTypes from "prop-types"
import Link from 'gatsby-link'
import { useStaticQuery, graphql } from "gatsby"

import Github from '../svg/github.svg'
import Twitter from '../svg/twitter.svg'
import Linkedin from '../svg/linkedin.svg'
import { MailOutline } from '@material-ui/icons'

import { DesktopNav, MobileNav } from '../components/nav'

const Layout = ({ children, className = "" }) => {
  const rawData = useStaticQuery(graphql`
    query SocialLinksQuery {
      allContact {
        edges {
          node {
            coordonnees {
              value
              name
              link
            }
          }
        }
      }
    }
  `)
  const coordonnees = rawData.allContact.edges[0].node.coordonnees;
  const contactInfos = coordonnees.filter(coordonnee => {
    if (coordonnee.name === "linkedin" ||
      coordonnee.name === "twitter" ||
      coordonnee.name === "github") {
      return coordonnee
    }
  })

  return (
    <>
      <div className={className}>
        <div className="container" style={{ background: 'none' }}>
          <DesktopNav />
          <MobileNav />
          <main>{children}</main>
          <footer>
            <div className="mobile-only">
              <Link to="/contact" className="button--contact"><MailOutline /> ME CONTACTER</Link>
            </div>
            <div>
              <ul className="social-icons-list">
                {contactInfos.map(info => (
                  <li>
                    <a href={info.link} target="_blank" rel="noopener noreferrer">
                      {/* [{info.name} icon] */}
                      {
                        info.name === 'twitter' ? <Twitter className="social-icon" /> :
                          info.name === 'github' ? <Github className="social-icon" /> :
                            <Linkedin className="social-icon" />
                      }
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
