import React from 'react'
import Link from 'gatsby-link'

import { Home } from '@material-ui/icons'
import { MailOutline } from '@material-ui/icons'

const DesktopNav = () => {
  return (
    <nav className="nav nav-desktop">
      <Link to="/"><Home className="icon-home-desktop" /></Link>
      <ul>
        <li><Link to="/a-propos">À PROPOS</Link></li>
        <li><Link to="/competences">COMPÉTENCES</Link></li>
        <li><Link to="/projets">MES PROJETS</Link></li>
        <li><Link to="/contact" className="button--contact__desktop"><MailOutline /> ME CONTACTER</Link></li>
      </ul>
    </nav>
  )
}

const MobileNav = () => {
  return (
    <nav className="nav nav-mobile">
      <Link to="/"><Home className="icon-home" /></Link>
      <ul>
        <li><Link to="/a-propos">À PROPOS</Link></li>
        <li><Link to="/competences">COMPÉTENCES</Link></li>
        <li><Link to="/projets">MES PROJETS</Link></li>
      </ul>
    </nav>
  )
}

export { DesktopNav, MobileNav }